import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroSplit";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Home Mortgage Basics | Thistle Financial"
        description="New to home loans? Get an application checklist, find out how appraisals work, and learn more about costs and loan programs in our Mortgage Basics."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        image={data.heroDesktop.childImageSharp.gatsbyImageData}
        imageAlignment="right"
        textWidth="1/2"
        imageWidth="1/2"
        minHeight="md:min-h-[626px]"
        padding="pb-16 md:pb-0"
      >
        <h1>Mortgage Basics</h1>
        <p>
          If you’re new to mortgages, this is a great place to start! Begin by
          reviewing the application checklist and then learn more about costs
          and other essential topics.
        </p>
        <ButtonSolid href="/request-rates/" text="Request Rates" />
      </Hero>

      <section className="bg-primary-50 py-20 md:py-32">
        <div className="container">
          <header className="max-w-2xl mb-16 md:mb-20">
            <h2>What to Take Into Consideration</h2>
          </header>

          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-20">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4 hidden md:block relative after:content after:absolute after:right-0 after:top-0 after:w-px after:h-full after:bg-primary-600/30">
              <li>
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="Application Checklist"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="Appraisal"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Closing Costs"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-4"}
                  title="Credit History"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-5"}
                  title="Foreclosures"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-6"}
                  title="Loan Programs"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-7"}
                  title="Refinancing Your Loan"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-8"}
                  title="Private Mortgage Insurance"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-9"}
                  title="Up-Front Expenses"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <div
                id="anchor-1"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Application Checklist</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Preparing your documentation in advance can help ensure your
                  loan application gets off on the right foot and will help
                  speed up the process. Typically, you’ll need to provide copies
                  of these items:
                </p>

                <ul className="list-check mb-6">
                  <li>Photo ID</li>
                  <li>Pay stubs</li>
                  <li>W-2s</li>
                  <li>Bank statements</li>
                  <li>Documentation for any other assets you have</li>
                  <li>
                    A gift letter if a family member or friend is giving you
                    money to help with your home purchase
                  </li>
                </ul>

                <p>
                  Lenders will also pull your credit report, so taking the time
                  to review it in advance is a good idea. You’ll want to be
                  prepared to explain any blemishes on your report, for example.
                  To get started, visit{" "}
                  <a
                    href="https://www.annualcreditreport.com/index.action"
                    target="_blank"
                    rel="noreferrer"
                  >
                    annualcreditreport.com
                  </a>{" "}
                  to request your credit report from the three major reporting
                  companies—Equifax, Experian, and TransUnion. You’re entitled
                  to a free report from these companies every 12 months.
                </p>
              </div>

              <div
                id="anchor-2"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Appraisal</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Appraisals are key to mortgage loans because they give lenders
                  an estimate of a property’s fair market value. Only a
                  state-licensed professional is qualified to appraise a
                  property. They inspect the physical condition of the property
                  and take into account its location and comparable properties
                  in the area and other factors.
                </p>
              </div>

              <div
                id="anchor-3"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Closing Costs</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  When it’s time to close on your home, you’ll be asked to pay
                  final closing costs, which include taxes and fees to state and
                  local agencies, third-party costs (such as attorney fees), and
                  finance and lender fees. The amount will vary depending on
                  your loan.
                </p>
              </div>

              <div
                id="anchor-4"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Credit History</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Lenders use a credit report to get a complete picture of your
                  finances and ensure you are a responsible borrower. They’ll
                  look at your credit report for indications of your bill-paying
                  history, any late payments or collections that may be on your
                  report, the number of accounts (and type of accounts) you
                  have, and the age of your accounts.
                </p>
                <h4>What If My Credit Score Is Low?</h4>
                <p>
                  A good credit score can certainly help you get favorable
                  mortgage terms. However, if your score is low, here are three
                  steps you can take to begin improving it:
                </p>

                <ul className="list-check mb-6">
                  <li>Pay your bills on time. </li>
                  <li>
                    Are you close to the credit limits on your credit cards? Pay
                    these down.
                  </li>
                  <li>
                    Don’t apply for new credit, especially while trying to get a
                    home loan. Your score may drop if you have too many credit
                    inquiries.
                  </li>
                </ul>

                <p>
                  Keep in mind that it can take time to improve a credit score.
                  But if you focus your efforts correctly, you’ll see a change
                  moving forward.
                </p>

                <h4>
                  What Happens If I’m Denied Or I Don’t Get The Terms I Want?
                </h4>
                <p>
                  A creditor is required to inform you why your application was
                  rejected within 60 days. For example, some reasons might
                  include your income being too low, or you haven’t been
                  employed long enough.
                </p>
              </div>

              <div
                id="anchor-5"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Foreclosures</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  If you’re unable to make your mortgage payments a lender can
                  seize and sell your property according to the terms of your
                  contract.
                </p>
                <h4>How Can I Avoid Foreclosure?</h4>
                <p>
                  If you’re struggling to make your mortgage payment, the first
                  plan of action is to call your lender to open a dialogue. You
                  want to make sure they understand your situation and that all
                  of your options are discussed. Many lenders have solutions
                  that can help, such as putting you on a repayment plan or
                  issuing a forbearance.
                </p>
              </div>

              <div
                id="anchor-6"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Loan Programs</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>

                <h4>FHA Home Loan</h4>
                <p>
                  An FHA loan is one that’s guaranteed by the government and is
                  typically a good option for first-time home buyers or those
                  with a spotty credit history.
                </p>

                <p className="mb-4">
                  <b>Benefits</b>
                </p>
                <ul className="list-check mb-6">
                  <li>Smaller down payment</li>
                  <li>Lower interest rates</li>
                  <li>Easier on credit-challenged borrowers</li>
                  <li>100% of the down payment can be in the form of a gift</li>
                </ul>

                <h4>VA Home Loan</h4>
                <p>
                  Active service members, veterans, and their surviving spouses
                  can purchase a home with a VA loan, which is offered by the
                  U.S. Department of Veterans Affairs. To qualify, the VA will
                  verify military status.
                </p>

                <p className="mb-4">
                  <b>Benefits</b>
                </p>
                <ul className="list-check mb-6">
                  <li>No down payment</li>
                  <li>No minimum credit score</li>
                  <li>Private Mortgage Insurance (PMI) is not required</li>
                  <li>Pay off loan at any time with no penalty</li>
                </ul>
              </div>

              <div
                id="anchor-7"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Refinancing Your Loan</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  When mortgage rates drop 1% to 2% lower than your current loan
                  rate, it’s an excellent time to consider refinancing. The most
                  significant benefit is that your monthly mortgage payment is
                  likely to drop, saving you money in your budget.
                </p>
              </div>

              <div
                id="anchor-8"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Private Mortgage Insurance</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Lenders use Private Mortgage Insurance (PMI) to protect
                  themselves if you can't pay your mortgage and end up
                  defaulting. You'll typically be required to purchase PMI if
                  your down payment is less than 20%.
                </p>
              </div>

              <div
                id="anchor-9"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Up-Front Expenses</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  When purchasing a new home, there are some additional up-front
                  expenses to be aware of, such as the costs for:
                </p>
                <ul className="list-check mb-6">
                  <li>Inspections</li>
                  <li>Appraisal fee</li>
                  <li>Moving expenses</li>
                  <li>Transfer of utilities</li>
                  <li>Title insurance</li>
                  <li>Escrow funds</li>
                </ul>
              </div>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <ValueProps />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: { eq: "2.7 Mortgage Basics/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
  }
`;
export default Page;
